<template>
    <b-container>
        <b-row>
            <b-col cols="12" sm="5" class="mb-4 mt-3">
                <h1>Hareket Dökümü</h1>
            </b-col>
            <b-col cols="12" sm="7" class="mb-4 mt-3">
                <b-row>
                    <b-col class="no-padding">
                        <b-form-select v-model="secilenBolge" :options="bolgeler" class="no-radius" size="sm" style="height: 34px;"></b-form-select>
                    </b-col>

                    <b-col class="no-padding">
                        <date-picker
                            v-model="time"
                            range
                            format="YYYY-MM-DD" 
                            value-type="YYYY-MM-DD"
                            placeholder="Tarih Seçin"
                            range-separator=" / "
                            size="sm"
                            confirm-text='Tamam'></date-picker>
                    </b-col>
                </b-row>
            </b-col>
        </b-row>
        <b-row>
            <b-col>
                <p><b>Toplam:</b> {{miktar(toplamMiktar)}}</p>
            </b-col>
        </b-row>
        <b-row>
            <b-col class="mt-2">
                <b-row v-if="hareketDokumu.length > 0">
                    <b-col>
                        <b-table responsive striped hover :fields="fields" :items="hareketDokumu">
                            <template v-slot:cell(sira)="sira">
                                {{sira.index + 1}}
                            </template>

                            <template v-slot:cell(atik_alim_konteyner)="detay">
                                <b-badge @click="konumGetir(detay.item.atik_alim_konteyner)" v-b-modal.modal-1 class="mr-1" size="sm" variant="Light">{{detay.item.atik_alim_konteyner}}</b-badge>
                            </template>

                            <template v-slot:cell(atik_alim_photo)="photo">
                                <b-badge v-if="photo.item.atik_alim_photo" @click="photoGetir(photo.item.atik_alim_photo)" v-b-modal.modal-2 class="mr-1" size="sm" variant="Light">
                                    <b-icon-search></b-icon-search>
                                </b-badge>
                            </template>

                            <template v-slot:cell(detay)="others">
                                <b-badge class="hover" @click="sil(others.item.atik_alim_id)" size="sm" variant="danger">
                                    Sil
                                </b-badge>
                            </template>
                        </b-table>
                    </b-col>
                </b-row>
                <b-row v-else>
                    <b-col>
                        <p>Sistemde kayıtlı hareket bulunamadı!</p>
                    </b-col>
                </b-row>

                <b-modal id="modal-1" title="Haritadaki Konum" hide-footer hide-header>
                    <gmap-map
                        :center="coords"
                        :zoom="12"
                        style="width: 100%;height: 400px"
                        :options="{disableDefaultUI:true}">
                        <gmap-marker
                            :position="coords"></gmap-marker>
                    </gmap-map>
                    <b>Adres:</b> {{adres}}<br/>
                    <b>Bölge:</b> {{bolge ? bolge : '-'}}
                </b-modal>

                <b-modal id="modal-2" title="Haritadaki Konum" hide-footer hide-header>
                    <img :src="'https://srv.sadam.com.tr/konteyner_takip/upload/' + photo" class="photo" />
                </b-modal>
            </b-col>
        </b-row>
    </b-container>
</template>

<script>
import Swal from 'sweetalert2';
import DatePicker from 'vue2-datepicker';
import 'vue2-datepicker/index.css';

export default{
    data(){
        return {
            fields : [
                {
                    "key" : "sira",
                    "label" : "",
                    "thStyle": { width: '50px'},
                },
                {
                    "key" : "atik_alim_konteyner",
                    "label" : "#",
                    "sortable": "true",
                    "thStyle": { width: '70px'},
                },
                {
                    "key" : "bolge_ad",
                    "label" : "Bölge",
                    "sortable": "true",
                    "thStyle": { width: '180px'},
                },
                {
                    "key" : "user_plaka",
                    "label" : "Plaka",
                    "sortable": "true",
                    "thStyle": { width: '170px'},
                },
                {
                    "key" : "user_adsoyad",
                    "label" : "Şoför",
                    "sortable": "true",
                    "thStyle": { width: '170px'},
                },
                {
                    "key" : "atik_alim_tarih",
                    "label" : "Tarih",
                    "sortable": "true",
                },
                {
                    "key" : "atik_alim_miktar",
                    "label" : "Miktar",
                    "sortable": "true"
                },
                {
                    "key" : "atik_alim_photo",
                    "label" : "Photo",
                    "thStyle": { width: '70px'},
                },
                {
                    "key" : "detay",
                    "label" : "",
                    "thStyle": { width: '30px'},
                },
            ],
            coords: null,
            adres: null,
            photo: null,
            secilenBolge: null,
            secilenBelediye: null,
            bolge: null,
            time: null,
        }
    },

    mounted(){
        if(this._hareketDokumu){
            // this.hareketDokumu = this._hareketDokumu;
        }
    }, 
    
    computed: {
        hareketDokumu(){
            var hareketDokumu = this.$store.state.hareketDokumu;
            var start;
            var finish;
            if(this.secilenBolge){
                if(this.time){
                    start = new Date(this.time[0]).getTime();
                    finish = new Date(this.time[1]).getTime();

                    return hareketDokumu.filter(r => {
                        var gun = r.atik_alim_tarih.split(" ");
                        var bugun = new Date(gun[0]).getTime()
                        if(bugun >= start && bugun <= finish && r.kk_bolge == this.secilenBolge)
                            return true;
                    });
                }else{

                    return hareketDokumu.filter(c => c.kk_bolge == this.secilenBolge);
                }
            }else{
                if(this.time){
                    start = new Date(this.time[0]).getTime();
                    finish = new Date(this.time[1]).getTime();

                    return hareketDokumu.filter(r => {
                        var gun = r.atik_alim_tarih.split(" ");
                        var bugun = new Date(gun[0]).getTime()
                        if(bugun >= start && bugun <= finish)
                            return true;
                    });
                    
                }else{
                    return hareketDokumu;
                }
            }
        },
        bolgeler(){
            var data = [{
                value: null,
                text: "Tüm Bölgeler"
            }];
            this.$store.state.bolgeler.map(c => {
                var bolge = {
                    value: c.bolge_id,
                    text: c.bolge_ad,
                }
                data.push(bolge);
                return bolge;
            });
            return data;
        },
        toplamMiktar(){
            var toplam = 0;
            for(var i=0; i<this.hareketDokumu.length;i++){
                toplam = toplam + parseFloat(this.hareketDokumu[i].atik_alim_miktar);
            }
            return toplam;
        },
        belediyeler(){
            var data = [{
                value: null,
                text: "Belediye Seçin"
            }];
            this.$store.state.users.filter(r => r.user_rutbe == 2).map(c => {
                var belediye = {
                    value: c.user_id,
                    text: c.user_adsoyad
                }
                data.push(belediye);
                return belediye;
            })
            return data;
        },
    },

    methods: {
        sil(a){
            Swal.fire({
                title: 'Emin Misin?',
                text: "Alım bilgisi sistemden kalıcı olarak silinecektir!",
                icon: 'warning',
                showCancelButton: true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor: '#d33',
                confirmButtonText: 'Evet',
                cancelButtonText: 'Hayır',
            }).then((result) => {
                if (result.isConfirmed) {
                    this.$store.dispatch("atikAlimSil", a);
                }
            })
        },
        konumGetir(a){
            var index = this.hareketDokumu.findIndex(c => c.atik_alim_konteyner == a);
            if(index > -1){
                var konteyner = this.hareketDokumu[index];
                this.coords = {
                    lat: Number(konteyner.kk_lat),
                    lng: Number(konteyner.kk_lng)
                };
                this.adres = konteyner.kk_adres;
                this.bolge = konteyner.bolge_ad;
            }    
        },

        photoGetir(a){
            this.photo = a;
        },

        miktar(miktar){
            return miktar.toFixed(2);
        }
    },

    components: { DatePicker },

    watch: {
        secilenBelediye(a){
            if(a){
                this.secilenBolge = null;
            }
        },

        time(time){
            var popup = Swal.fire({
                icon: 'info',
                title: 'Lütfen Bekleyin!',
                text: 'Sunucudan veri alınırken lütfen bekleyin.',
                showConfirmButton: false,
            });
            this.$store.dispatch("HareketDokumu", {
                start: time[0],
                end: time[1]
            }).then(() => {
                popup.close();
            })
        }
    },
}
</script>

<style scoped>
h1{
    font-size: 1.5rem;
    font-weight: 700;
    text-transform: uppercase;
    margin-top: 10px;
    margin-bottom: 0;
}
.photo{
    width: 100%;
    height: auto;
}
.hover:hover{
    cursor: pointer;
}
</style>